import React from 'react';
import School from '../../models/School';
import SchoolOption from './SchoolOption';
import { container } from './index.style';
import { useFormikContext } from 'formik';
import FormValues from '../../models/FormValues';

interface Props {
    schoolList: School[];
}

export default function SchoolList(props: Props) {
    const { setFieldValue, values } = useFormikContext<FormValues>();
    const { schoolList } = props;
    const { selectedSchool } = values;
    return (
        <div css={container}>
            {schoolList.map((school) => {
                const { address } = school;
                return (
                    <SchoolOption
                        isActive={selectedSchool?.id === school.id}
                        key={school.id}
                        schoolName={school.name}
                        address={address}
                        onClick={() => {
                            setFieldValue('selectedSchool', school);
                        }}
                    />
                );
            })}
        </div>
    );
}
