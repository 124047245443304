import React from 'react';
import { container, header, active, subtitle, doneIcon, text } from './index.style';
import { materialFont } from '../../../global.style';

interface Props {
    isActive?: boolean;
    schoolName: string;
    address: {
        addressLine1: string;
        addressLine2: string;
        addressTown: string;
        addressPostcode: string;
        addressCountry: {
            code: string;
            name: string;
        };
    };
    onClick: (event: React.MouseEvent) => void;
}

export default function SchoolOption(props: Props) {
    const { isActive, schoolName, onClick, address } = props;
    const addressString = `${address.addressLine1}, ${address.addressTown}, ${address.addressPostcode}`;
    return (
        <button onClick={onClick} css={[container, ...(isActive ? [active] : [])]}>
            <div css={text}>
                <p css={header}>{schoolName}</p>
                <p css={subtitle}>{addressString}</p>
            </div>
            {isActive && <i css={[materialFont, doneIcon]}>done</i>}
        </button>
    );
}
