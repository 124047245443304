import { css } from '@emotion/core';
import { primaryColour, breakpoint, ScreenWidth } from '../../../global.style';

export const container = css({
    position: 'relative',
    textAlign: 'center',
    padding: '0px 15px',
    color: primaryColour,
    h1: {
        marginTop: '0px',
        marginBottom: '8px',
        fontSize: '20px',
    },
    width: '79%',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '66vh',
    [breakpoint(ScreenWidth.LG)]: {
        padding: '0px',
        h1: {
            marginBottom: '23px',
            fontSize: '43px',
        },
    },
});

export const schoolScrollList = css({
    overflowY: 'scroll',
    paddingRight: '10px',
    height: '25vh',
    width: '100%',
    '@media screen and ( min-height: 700px )': {
        paddingRight: '15px',
        height: '29vh',
    },
    '@media screen and ( min-height: 900px )': {
        paddingRight: '15px',
        height: '40vh',
    },
});

export const nextButton = css({
    position: 'absolute',
    right: '0px',
    bottom: '35px',
    '@media screen and ( min-height: 900px )': {
        bottom: '0px',
    },
});

export const searchResultText = css({
    width: '100%',
    display: 'block',
    textAlign: 'right',
    fontSize: '13px',
    [breakpoint(ScreenWidth.MD)]: {
        fontSize: '15px',
        letterSpacing: '0.54px',
    },
});

export const searchTextInput = css({
    width: '100%',
    fontSize: '14px',
    [breakpoint(ScreenWidth.MD)]: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
});
