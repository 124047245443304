import { css } from '@emotion/core';
import { activeColour, navigationColour, breakpoint, ScreenWidth } from '../../../global.style';

export const container = css({
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '7px',
    border: '1px solid rgba(144, 136, 155, 0.45)',
    margin: '23px 0px',
    color: navigationColour,
    height: '73px',
    cursor: 'pointer',
    outline: 'none',
});

export const text = css({
    margin: '13px 0px 16px 5px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '80%',
    height: '100%',
});

export const header = css({
    fontWeight: 'bold',
    fontSize: '13px',
    margin: '0px 0px 5px 0px',
    maxWidth: '85%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    letterSpacing: '0.54px',
    [breakpoint(ScreenWidth.SM)]: {
        maxWidth: '80%',
        fontSize: '19px',
    },
});

export const subtitle = css({
    fontSize: '11px',
    margin: '0px',
    letterSpacing: '0.54px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '95%',
    [breakpoint(ScreenWidth.XS)]: {
        maxWidth: '80%',
        fontSize: '15px',
    },
});

export const doneIcon = css({
    textAlign: 'right',
    width: '20%',
    fontSize: '32px',
    margin: '10px 15px 13px 13px',
    height: '5%',
    [breakpoint(ScreenWidth.XS)]: {
        height: '20%',
        fontSize: '50px',
    },
});

export const active = css({
    backgroundColor: activeColour,
    border: 'none',
    color: 'white',
    opacity: '1',
});
