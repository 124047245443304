import { secondaryColour, primaryFont } from '../../global.style';
import styled from '@emotion/styled';

const SquareButton = styled.button({
    outline: 'none',
    borderRadius: '3.5px',
    backgroundColor: secondaryColour,
    color: 'white',
    padding: '11px 2rem',
    fontFamily: primaryFont,
    letterSpacing: '0.51px',
    fontWeight: 'bold',
    fontSize: '23px',
    width: '181px',
    cursor: 'pointer',
    '*': {
        transitionDuration: '0.4s',
        textDecoration: 'none',
        color: 'white',
    },
    transitionDuration: '0.4s',
    ':hover:enabled': {
        backgroundColor: 'white',
        color: secondaryColour,
        '*': {
            color: secondaryColour,
        },
    },
    ':disabled': {
        opacity: 0.15,
    },
});

export default SquareButton;
