import React from 'react';
import { container, input, search } from './index.style';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { materialFont } from '../../global.style';

interface Props {
    name: string;
    type: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
    autocomplete?: 'on' | 'off';
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showSearch?: boolean;
}

export default function TextInput(props: Props) {
    const { showSearch, name, type, placeholder, className, disabled, readOnly, autocomplete, onChange } = props;
    return (
        <div css={container}>
            {showSearch && (
                <div id={'searchIcon'} css={search}>
                    <i css={[materialFont]}>search</i>
                </div>
            )}
            <input
                css={input}
                name={name}
                type={type}
                placeholder={placeholder}
                className={className}
                disabled={disabled}
                readOnly={readOnly}
                autoComplete={autocomplete}
                onChange={onChange}
            />
        </div>
    );
}
