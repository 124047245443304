import BackEndSchool from '../models/back-end/BackEndSchool';
import School from '../models/School';

export default class SchoolAdapter {
    public adapt(item: BackEndSchool): School {
        return {
            id: item.id,
            name: item.name,
            address: {
                addressLine1: item.address.address_line_1,
                addressLine2: item.address.address_line_2,
                addressCountry: item.address.address_country,
                addressPostcode: item.address.address_postcode,
                addressTown: item.address.address_town,
            },
        };
    }
}
