import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import SEO from '../../../components/SEO';
import { container, schoolScrollList, nextButton, searchResultText } from './index.style';
import TextInput from '../../../components/TextInput';
import axios from 'axios';
import useDebounce from '../../../utilities/useDebounce';
import SquareButton from '../../../components/SquareButton';
import School from '../../../models/School';
import FormValues from '../../../models/FormValues';
import SchoolList from '../../../components/SchoolList';
import SchoolAdapter from '../../../adapters/SchoolAdapter';
import BackEndSchool from '../../../models/back-end/BackEndSchool';

export default function MySchool() {
    const { t } = useTranslation('', { useSuspense: false });
    const [isLoading, setLoading] = useState(false);
    const [schoolList, setSchoolList] = useState<School[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const debouncedSearchTerm = useDebounce(searchQuery, 500);
    const { values } = useFormikContext<FormValues>();
    const { selectedSchool } = values;
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    useEffect(() => {
        if (debouncedSearchTerm) {
            setLoading(true);
            const fetchData = async () => {
                try {
                    const response = await axios('https://s3.dpr.education/media/schools.json', {
                        cancelToken: source.token,
                    });
                    const schoolAdapter = new SchoolAdapter();
                    const schoolList = response.data.map((school: BackEndSchool) => schoolAdapter.adapt(school));
                    setSchoolList(schoolList);
                    setLoading(false);
                } catch (e) {
                    if (!axios.isCancel(e)) {
                        setLoading(false);
                    }
                }
            };
            fetchData();
        } else {
            setSchoolList([]);
        }
        return () => {
            source.cancel();
        };
    }, [debouncedSearchTerm]);

    const onNextClick = () => {
        localStorage.setItem(
            'formSession',
            JSON.stringify({
                formValues: values,
                page: '/sign-up/my-school',
            }),
        );
    };

    return (
        <div css={container}>
            <SEO title={t('signUp.mySchool.title')} description={t('signUp.mySchool.description')} />
            <h1>{t('signUp.mySchool.heading')}</h1>
            <TextInput
                showSearch={true}
                name={'search-field'}
                type="text"
                onChange={(e) => {
                    setSearchQuery(e.currentTarget.value);
                }}
                placeholder={t('signUp.mySchool.search')}
            />
            {schoolList.length > 0 && (
                <p css={searchResultText}>{t('signUp.mySchool.foundSearchResults', { length: schoolList.length })}</p>
            )}
            {schoolList.length > 0 && (
                <div css={schoolScrollList}>{!isLoading && <SchoolList schoolList={schoolList} />}</div>
            )}
            <div css={nextButton}>
                <SquareButton disabled={!selectedSchool} onClick={onNextClick}>
                    {t('general.next')}
                </SquareButton>
            </div>
        </div>
    );
}
