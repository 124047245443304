import { css } from '@emotion/core';
import { primaryFont, navigationColour } from '../../global.style';

export const container = css({
    border: '1px solid transparent',
    borderRadius: '5px',
    display: 'flex',
    width: '100%',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.18)',
});

export const search = css({
    padding: '16px 5px 10px 20px',
    backgroundColor: 'white',
    color: navigationColour,
});

export const input = css({
    width: '100%',
    fontFamily: primaryFont,
    border: '1px solid white',
    letterSpacing: '0.4px',
    fontSize: '1em',
    padding: '5px',
    color: navigationColour,
    outline: 'none',
});
